/** @jsx jsx */
import { CDNImage } from "@boxoffice/screenplay";
import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { getPresentationHeight } from "shared/helpers/image";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { AspectRatio, jsx } from "theme-ui";

import { TheaterPicturesQuery } from "../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetTheaterPictures" }>;
}

const TheaterPicturesWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const pageContext = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();
  const resolvedSelectedTheaterId = pageContext.theaterId || selectedTheaterId;

  const data = useStaticQuery<TheaterPicturesQuery>(graphql`
    query TheaterPictures {
      allTheater {
        nodes {
          id
          name
          practicalInfo {
            images {
              url
              probe {
                ratio
              }
            }
          }
        }
      }
    }
  `);

  const selectedTheaterData = !pageContext.isSingleLocation
    ? data.allTheater.nodes.find(({ id }) => id === resolvedSelectedTheaterId)
    : data.allTheater.nodes[0];

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      {selectedTheaterData?.practicalInfo?.images?.[0] ? (
        <CDNImage
          src={selectedTheaterData?.practicalInfo?.images?.[0].url}
          alt={selectedTheaterData?.name || ""}
          width={1472}
          height={getPresentationHeight(
            1472,
            selectedTheaterData?.practicalInfo?.images?.[0].probe?.ratio
          )}
          fit={"cover"}
          sx={{
            width: "100%",
          }}
        />
      ) : (
        <AspectRatio
          ratio={16 / 9}
          sx={{
            backgroundColor: "rgba(0,0,0,.25)",
            borderRadius: "medium",
          }}
        />
      )}
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetTheaterPictures on WidgetTheaterPictures {
    id
    __typename
  }
`;

export default memo(TheaterPicturesWidget);
